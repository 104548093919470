import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton } from '../../components';

import css from './ProfileListingPage.module.css';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT } from '../../util/types';
import config from '../../config';
import { richText } from '../../util/richText';

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionHeading = props => {
  const { priceTitle, formattedPrice, richTitle, category, description, openBookModal } = props;

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ProfileListingPage.perNight'
    : isDaily
    ? 'ProfileListingPage.perDay'
    : 'ProfileListingPage.perUnit';

  return (
    <div className={css.sectionHeading}>
      <h1 className={css.title}>{richTitle}</h1>
      <div className={css.author}>{category}</div>
      {description ? (
          <p className={css.description}>
            {richText(description, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
              longWordClass: css.longWord,
            })}
          </p>
      ) : null}
      <div className={css.desktopPriceContainer}>
        <div className={css.desktopPriceValue} title={priceTitle}>
          {formattedPrice}
        </div>
        <div className={css.desktopPerUnit}>
          <FormattedMessage id={unitTranslationKey} />
        </div>
      </div>
      <div onClick={openBookModal} className={css.bookButton}>
        Book now
      </div>
    </div>
  );
};

export default SectionHeading;
