import React, { useState } from 'react';

import css from './SectioMentorSuggestions.module.css';

import CoursePaper from '../CoursePaper/CoursePaper';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import uniqueId from 'lodash/uniqueId';
import LinkButton from '../LinkButton/LinkButton';


const SectionMentorSuggestions = ({postArr, title, description, link, hasButton = false}) => {
  const history = useHistory();
  return (
    <div className={css.root}>
      <div className={css.textContainer}>
        <p className={css.title}>{title}</p>
      </div>
      <div className={css.recommendationsContainer}>
        <div className={css.recomTop}>
          <p className={css.recomTitle}>{description}</p>
          <a onClick={() => history.push(link)} className={css.seeAll}>
            See all {'>'}
          </a>
        </div>
        <div className={css.recomPapersContainer}>
          {postArr.map(elem => {
            return (
              <CoursePaper
                key={uniqueId("MeetMentor")}
                link={elem.link}
                price={elem.price}
                title={elem.title}
                ranking={elem.ranking}
                rankingAmount={elem.rankingAmount}
                mentorName={elem.mentorName}
                image={elem.image}
              />
            );
          })}
        </div>
        {hasButton && <LinkButton text={"Explore Mentors"} link={link}/>}
      </div>
      <div className={css.overlay}/>
    </div>
  );
};

export default SectionMentorSuggestions;
